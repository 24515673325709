import { computed } from "vue";
import { useStore } from "vuex";
const useFindBu = () => {
  const { state } = useStore();
  const buList = computed(() => state.user.buList);
  const buIdMap = buList.value.reduce((obj: any, item: any) => {
    return { ...obj, [item.nameEn]: item.id };
  }, {});
  const findBu = () => {
    const mbIndex = buList.value.findIndex((i: any) => {
      return i.nameEn === "MBPC";
    });
    const initIndex = mbIndex === -1 ? 0 : mbIndex;
    return buList.value[initIndex].nameEn;
  };
  return {
    buList,
    findBu,
    buIdMap,
  };
};
export default useFindBu;
