import { ref } from "vue";
import { useStore } from "vuex";
import { findBuVehicleList } from "@/API/approve";
// import deepClone from '@/utils/deepClone'
export interface BuOptionProps {
  id: string;
  name: string;
}
const useBuMakeTP = <T>(queryParams: T, isNst = false) => {
  const store = useStore();
  const buOptions = ref<BuOptionProps[]>([]);
  const makeOptions = ref();
  const modelOptions = ref();
  //brand options
  const brandOptions = ref();
  const typeClassOptions = ref();
  const resetObj = {};
  const data = ref<any>(queryParams);
  // 获取bu make brand typeclass联动的接口
  const intoFull = (target: string, isMultiple: boolean) => {
    switch (target) {
      case "buId":
        buOptions.value = [];
        data.value.bu = isMultiple ? [] : "";
        break;
      case "make":
        makeOptions.value = [];
        data.value.make = isMultiple ? [] : "";
        break;
      case "brand":
        brandOptions.value = [];
        data.value.brand = isMultiple ? [] : "";
        break;
      case "model":
        modelOptions.value = [];
        data.value.model = isMultiple ? [] : "";
        break;
      case "typeClass":
        typeClassOptions.value = [];
        data.value.typeClass = isMultiple ? [] : "";
        break;
    }
  };
  // 因为下拉接口要buId
  const buNameIdMap = () => {
    return store.state.user.buList.reduce((maps: any, bu: any) => {
      return { ...maps, [bu.nameCn]: bu.id };
    }, {});
  };

  const buNameIdMapList = buNameIdMap();
  // 获取焦点获取数据
  const handleFocus = (target: string, isMultiple: boolean, isAll = false) => {
    const params = {
      buId: typeof data.value.bu === 'string' ? buNameIdMapList[data.value.bu] : data.value.bu.map((b: any) => {
        return buNameIdMapList[b];
      }),
      brand: data.value.brand,
      make: data.value.make,
      typeClass: data.value.typeClass,
      model: data.value.model,
      nsttype: !isNst ? "" : "nst",
      target,
    };
    intoFull(target, isMultiple);

    return new Promise((resolve, reject) => {
      findBuVehicleList(params)
        .then((res: BuOptionProps[]) => {
          switch (target) {
            case "buId":
              buOptions.value = res;
              break;
            case "make":
              makeOptions.value = res;
              break;
            case "brand":
              brandOptions.value = res;
              break;
            case "model":
              modelOptions.value = res;
              break;
            case "typeClass":
              typeClassOptions.value = res;
              break;
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  Object.assign(resetObj, queryParams);
  return {
    handleFocus,
    buOptions,
    makeOptions,
    brandOptions,
    typeClassOptions,
    modelOptions,
    resetObj,
    buNameIdMap,
  };
};
export default useBuMakeTP;
